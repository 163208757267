<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab x-small color="blue" class="ml-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Editar datos personales
      </v-card-title>
      <v-card-text>
        <v-text-field label="Nombre" v-model="cliente.nombre"></v-text-field>

        <v-text-field
          label="Primer apellido"
          v-model="cliente.primerApellido"
        ></v-text-field>

        <v-text-field
          label="Segundo Apellido"
          v-model="cliente.segundoApellido"
        ></v-text-field>

        <v-text-field label="DNI" v-model="cliente.dni"></v-text-field>

        <v-menu
          ref="menu"
          v-model="fechaNacimiento"
          :close-on-content-click="false"
          :return-value.sync="cliente.fechaNacimiento"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="cliente.fechaNacimiento"
              label="Fecha de nacimiento"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="cliente.fechaNacimiento" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fechaNacimiento = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="cliente.fechaNacimiento = ''">
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(cliente.fechaNacimiento)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
          label="Domicilio"
          v-model="cliente.residencia"
        ></v-text-field>

        <v-combobox
          v-model="cliente.colegio"
          :items="colegios"
          label="Colegio"
          clearable
        ></v-combobox>

        <v-select
          :items="cursos"
          label="Curso"
          clearable
          v-model="cliente.curso"
        ></v-select>

        <v-select
          :items="niveles"
          label="Nivel"
          clearable
          v-model="cliente.nivel"
        ></v-select>

        <v-text-field
          label="Observaciones"
          v-model="cliente.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editar()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarCliente',
  props: ['cliente'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
      fechaNacimiento: false,
      cursos: ['1º', '2º', '3º', '4º', '5º', '6º', 'Otro'],
      colegios: [],
      niveles: [
        'Infantil',
        'Primaria',
        'Secundaria',
        'Bachillerato',
        'Opositores',
        'Adultos',
        'Otro',
      ],
    };
  },
  methods: {
    async editar() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/clientes/editar', {
          id: this.cliente._id,
          nombre: this.cliente.nombre,
          primerApellido: this.cliente.primerApellido,
          segundoApellido: this.cliente.segundoApellido,
          dni: this.cliente.dni,
          fechaNacimiento: this.cliente.fechaNacimiento,
          residencia: this.cliente.residencia,
          colegio: this.cliente.colegio,
          curso: this.cliente.curso,
          nivel: this.cliente.nivel,
          observaciones: this.cliente.observaciones,
        });

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
    async getColegios() {
      try {
        let clientes = await axios.get(process.env.VUE_APP_API_URL + '/clientes');
        clientes = clientes.data;

        const colegios = [];

        for (let i = 0; i < clientes.length; i++) {
          if (clientes[i].colegio) {
            if (colegios.indexOf(clientes[i].colegio) === -1) {
              colegios.push(clientes[i].colegio);
            }
          }
        }

        this.colegios = colegios;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getColegios();
  },
};
</script>
