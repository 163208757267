<template>
  <div>
    <v-card-title>
      Contactos

      <NuevoContacto
        :idCliente="$route.params.id"
        @edit="getTableData($route.params.id)"
      ></NuevoContacto>
    </v-card-title>
    <v-card-text>
      <p v-show="errorTableData" class="text-center">
        <v-icon>mdi-alert-circle</v-icon>
        Error obteniendo los datos
      </p>

      <v-data-table
        v-if="!errorTableData"
        :headers="tableHeaders"
        :items="tableData"
        :loading="loadingTableData"
        loading-text="Cargando..."
      >
        <template v-slot:item.acciones="{ item }">
          <v-icon
            v-show="item.correo"
            class="mr-2"
            color="cyan"
            @click="mail(item.correo)"
          >
            mdi-email
          </v-icon>

          <v-icon
            v-show="item.telefono"
            class="mr-2"
            color="purple"
            @click="call(item.telefono)"
          >
            mdi-phone
          </v-icon>

          <v-icon
            v-show="item.telefono"
            class="mr-2"
            color="green"
            @click="whatsapp(item.telefono)"
          >
            mdi-whatsapp
          </v-icon>

          <EditarContacto
            :contacto="item"
            @edit="getTableData($route.params.id)"
          ></EditarContacto>

          <v-icon color="red" @click="eliminarRow(item._id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import axios from 'axios';
import EditarContacto from './EditarContacto';
import NuevoContacto from './NuevoContacto';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: null,
      loadingTableData: true,
      tableHeaders: [
        {
          text: 'Nombre',
          value: 'nombre',
        },
        { text: 'Descripción', value: 'descripcion', sortable: false },
        { text: 'Correo', value: 'correo', sortable: false },
        { text: 'Teléfono', value: 'telefono', sortable: false },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async getTableData(idCliente) {
      try {
        this.loadingTableData = true;
        const res = await axios.get(process.env.VUE_APP_API_URL + '/contactos?idCliente=' + idCliente);
        this.tableData = res.data;
        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;
        this.errorTableData = error.response.data;
      }
    },
    async eliminarRow(id) {
      try {
        this.loadingTableData = true;
        await axios.post(process.env.VUE_APP_API_URL + '/contactos/eliminar', {
          id,
        });
        this.getTableData(this.$route.params.id);
      } catch (error) {
        alert('Error - No se ha podido eliminar');
      }
    },
    async mail(correo) {
      location.href = `mailto:${correo}`;
    },
    async call(telefono) {
      location.href = `tel:${telefono}`;
    },
    async whatsapp(telefono) {
      window.open(`https://wa.me/34${telefono}`, '_blank');
    },
  },
  components: {
    EditarContacto,
    NuevoContacto,
  },
  mounted() {
    this.getTableData(this.$route.params.id);
  },
};
</script>
