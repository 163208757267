<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="mr-2" color="orange" v-bind="attrs" v-on="on">
        mdi-pencil
      </v-icon>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Editar contacto
      </v-card-title>
      <v-card-text>
        <v-text-field label="Nombre" v-model="contacto.nombre"></v-text-field>
        <v-text-field
          label="Descripción"
          v-model="contacto.descripcion"
        ></v-text-field>
        <v-text-field label="Correo" v-model="contacto.correo"></v-text-field>
        <v-text-field
          label="Teléfono"
          v-model="contacto.telefono"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editar()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarContacto',
  props: ['contacto'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
    };
  },
  methods: {
    async editar() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/contactos/editar', {
          id: this.contacto._id,
          nombre: this.contacto.nombre,
          descripcion: this.contacto.descripcion,
          correo: this.contacto.correo,
          telefono: this.contacto.telefono,
        });

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
