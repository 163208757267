<template>
  <div>
    <p v-show="errorCliente" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      {{ errorCliente }}
    </p>

    <v-card :loading="loadingCliente" v-if="!errorCliente">
      <v-card-title>
        <v-img contain max-height="150" src="../../assets/personas.png">
        </v-img>
      </v-card-title>

      <br />

      <h2 class="text-center">
        {{ cliente.nombre }} {{ cliente.primerApellido }}
        {{ cliente.segundoApellido }}
      </h2>

      <br />

      <v-divider></v-divider>

      <v-card-title>
        Datos personales

        <EditarCliente
          :cliente="cliente"
          @edit="getCliente($route.params.id)"
        ></EditarCliente>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <h3>Fecha de alta:</h3>
            <p>{{ new Date(cliente.fechaAlta).toLocaleString() }}</p>

            <h3>Nombre:</h3>
            <p>{{ cliente.nombre }}</p>

            <h3>Primer apellido:</h3>
            <p>{{ cliente.primerApellido }}</p>

            <h3>Segundo apellido:</h3>
            <p>{{ cliente.segundoApellido }}</p>

            <h3>DNI:</h3>
            <p>{{ cliente.dni }}</p>

            <h3>Fecha de nacimiento:</h3>
            <p>{{ new Date(cliente.fechaNacimiento).toLocaleDateString() }}</p>

            <h3>Domicilio:</h3>
            <p>{{ cliente.residencia }}</p>
          </v-col>

          <v-col>
            <h3>Colegio:</h3>
            <p>{{ cliente.colegio }}</p>

            <h3>Curso:</h3>
            <p>{{ cliente.curso }}</p>

            <h3>Nivel:</h3>
            <p>{{ cliente.nivel }}</p>

            <h3>Observaciones:</h3>
            <p>{{ cliente.observaciones }}</p>

            <h3>Última modificación:</h3>
            <p>
              {{ new Date(cliente.ultimaModificacion).toLocaleString() }}
            </p>

            <v-checkbox
              v-model="cliente.proteccionDatos"
              label="Protección de datos"
              @click="changeProteccionDatos"
            ></v-checkbox>

            <v-checkbox
              v-model="cliente.usoImagenes"
              label="Uso de imágenes"
              @click="changeUsoImagenes"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <Contactos></Contactos>

      <v-divider></v-divider>

      <v-card-actions class="mt-4">
        <v-btn color="red" @click="eliminarCliente(cliente._id)">
          <v-icon>mdi-delete</v-icon>
          Eliminar cliente
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import Contactos from '../Contactos/Contactos';
import EditarCliente from './EditarCliente';

export default {
  data() {
    return {
      cliente: {},
      errorCliente: null,
      loadingCliente: true,
    };
  },
  methods: {
    async getCliente(id) {
      try {
        this.loadingCliente = true;
        const res = await axios.get(process.env.VUE_APP_API_URL + '/clientes?id=' + id);
        this.cliente = res.data;
        this.loadingCliente = false;
      } catch (error) {
        this.loadingCliente = false;
        this.errorCliente = error.response.data;
      }
    },
    async changeProteccionDatos() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/clientes/editar', {
          id: this.cliente._id,
          proteccionDatos: this.cliente.proteccionDatos,
        });
      } catch (error) {
        alert('Error - No se ha podido cambiar la protección de datos');
      }
    },
    async changeUsoImagenes() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/clientes/editar', {
          id: this.cliente._id,
          usoImagenes: this.cliente.usoImagenes,
        });
      } catch (error) {
        alert('Error - No se ha podido cambiar el uso de imágenes');
      }
    },
    async eliminarCliente(id) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/clientes/eliminar', {
          id,
        });
        this.$router.push({ name: 'Clientes' });
      } catch (error) {
        alert('Error - No se ha podido eliminar el cliente');
      }
    },
  },
  components: {
    Contactos,
    EditarCliente,
  },
  mounted() {
    this.getCliente(this.$route.params.id);
  },
};
</script>
