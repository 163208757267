<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab x-small color="blue" class="ml-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nuevo contacto
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Nombre"
          v-model="nuevoContacto.nombre"
        ></v-text-field>
        <v-text-field
          label="Descripción"
          v-model="nuevoContacto.descripcion"
        ></v-text-field>
        <v-text-field
          label="Correo"
          v-model="nuevoContacto.correo"
        ></v-text-field>
        <v-text-field
          label="Teléfono"
          v-model="nuevoContacto.telefono"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" text @click="toggleDialog = false">
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NuevoContacto',
  props: ['idCliente'],
  data() {
    return {
      toggleDialog: false,
      loading: false,
      nuevoContacto: {
        idCliente: '',
        nombre: '',
        descripcion: '',
        correo: '',
        telefono: '',
      },
    };
  },
  methods: {
    async crear() {
      try {
        this.nuevoContacto.idCliente = this.idCliente;

        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/contactos/nuevo', this.nuevoContacto);

        this.toggleDialog = false;

        this.loading = false;

        this.$emit('edit');
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
